<template>
	<div class="ele-body">
		<el-card shadow="never">
			<el-tabs v-model="active" class="user-info-tabs">
				<!--课程列表-->
				<el-tab-pane label="课程列表" name="course_data">
					<el-form :model="table.where" class="ele-form-search d-flexspabet mt-20"
						@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
						<div>
							<el-button @click="showEdit=true" class="ele-btn-icon addbtn" size="small"
								v-if="permission.includes('sys:course:add')">添加课程</el-button>
						</div>
						<div class="d-flex">
							<el-form-item label="使用版:" label-width="62px" class="w-250">
								<el-select v-model="table.where.type" placeholder="请选择" clearable class="ele-fluid">
									<!-- <el-option label="咱县打车司机版" :value="2" />
									<el-option label="咱县打车跑腿版" :value="3" />
									<el-option label="咱县打车商家版" :value="4" /> -->
									<el-option label="可蚁点司机版" :value="2" />
									<el-option label="可蚁点跑腿版" :value="3" />
									<el-option label="可蚁点商家版" :value="4" />
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
									class="ele-btn-icon ml-20">搜索</el-button>
								<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
							</el-form-item>
						</div>
					</el-form>
					<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
						highlight-current-row :stripe=true>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="编号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="image" label="封面图：" show-overflow-tooltip width="240">
								<template slot-scope="{row}">
									<!-- <el-avatar shape="square" :size="20" :src="row.image" style="width:80px;height:30px" /> -->
									<el-image style="width:80px;height:30px;" :src="row.image" fit="cover" :preview-src-list="[row.image]"></el-image>
								</template>
							</el-table-column>
							<el-table-column prop="type_name" label="显示版" show-overflow-tooltip min-width="120" >
								<template slot-scope="{row}">
									{{row.type==2?'可蚁点司机版':(row.type==3?'可蚁点跑腿版':(row.type==4?'可蚁点商家版':'其他版'))}}
								</template>
							</el-table-column>
							<el-table-column prop="name" label="课程标题" show-overflow-tooltip min-width="120" />
							<el-table-column label="更新时间" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column prop="num" label="浏览次数(次)" show-overflow-tooltip />
							<el-table-column label="操作" width="220px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link @click="editWatch(row)" icon="el-icon-edit" type="primary"
										:underline="false" v-if="permission.includes('sys:course:edit')">查看</el-link>
									<el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false"
										v-if="permission.includes('sys:course:edit')">编辑</el-link>
									<el-link @click="remove(row)" slot="reference" icon="el-icon-delete" type="danger"
										:underline="false" v-if="permission.includes('sys:course:delete')">删除</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-tab-pane>
				<!--学习情况-->
				<el-tab-pane label="学习情况" name="course_study">
					<el-form :model="table1.where" class="ele-form-search  mt-20"
						@keyup.enter.native="$refs.table1.reload()" @submit.native.prevent>
						<div class="d-flex">
							<el-form-item label="开始时间:" label-width="76px">
								<el-date-picker style="width:150px" v-model="table1.where.start_time" type="date"
									placeholder="选择日期">
								</el-date-picker>
							</el-form-item>
							<el-form-item label="结束时间:" label-width="80px">
								<el-date-picker style="width:150px" v-model="table1.where.end_time" type="date"
									placeholder="选择日期">
								</el-date-picker>
							</el-form-item>
							<el-form-item label="类型:" label-width="50px" class="w-250">
								<el-select v-model="table1.where.type" placeholder="请选择类型" clearable class="ele-fluid">
									<!-- <el-option label="咱县打车司机版" :value="2" />
									<el-option label="咱县打车跑腿版" :value="3" />
									<el-option label="咱县打车商家版" :value="4" /> -->
									<el-option label="可蚁点司机版" :value="1" />
									<el-option label="可蚁点跑腿版" :value="2" />
									<el-option label="可蚁点商家版" :value="4" />
								</el-select>
							</el-form-item>
							<el-form-item label="姓名:" label-width="50px">
								<el-input v-model="table1.where.driver_name" placeholder="请输入姓名" class="input150" clearable />
							</el-form-item>
							<el-form-item label="手机号:" label-width="65px">
								<el-input v-model="table1.where.phone" placeholder="请输入手机号" class="input150" clearable />
							</el-form-item>
							<el-form-item label="省:" label-width="35px" class="w-150">
								<el-select v-model="table1.where.pid" @change="handleChangeProv(table1.where.pid)"
									placeholder="请选择省">
									<el-option v-for="option in provArr" :value="option.name" :key="option.pid">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="市:" label-width="35px" class="w-150">
								<el-select v-model="table1.where.cid"
									@change="handleChangeCity(table1.where.cid),$forceUpdate()" placeholder="请选择市">
									<el-option v-for="option in cityArr" :value="option.name" :key="option.cid">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="县/区:" label-width="62px" class="w-200">
								<el-select v-model="table1.where.aid" @change="$forceUpdate()" placeholder="请选择县/区">
									<el-option v-for="option in districtArr " :value="option.name" :key="option.aid">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="$refs.table1.reload()" icon="el-icon-search"
									class="ele-btn-icon ml-20">搜索</el-button>
								<el-button @click="(table1.where={})&&$refs.table1.reload()">重置</el-button>
							</el-form-item>
						</div>
					</el-form>
					<ele-data-table ref="table1" :config="table1" :choose.sync="choose" height="calc(100vh - 315px)"
						highlight-current-row :stripe=true>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="编号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<!-- <el-table-column prop="type" label="类型" show-overflow-tooltip width="140px">
								<template slot-scope="scope">
									{{scope.type==1?'咱县打车司机版':scope.type==2?'咱县打车商家版-超市':scope.type==3?'咱县打车商家版-叫餐':scope.type==5?'咱县打车商家版-跑腿':scope.type==5?'可蚁点司机版'
										:scope.type==6?'可蚁点商家版-超市':scope.type==7?'可蚁点商家版-叫餐':'可蚁点商家版-跑腿'}}&ndash;&gt;
								</template>
							</el-table-column> -->
							<el-table-column prop="type" label="类型" show-overflow-tooltip width="180">
								<!-- <template slot-scope="scope">
									{{scope.type==1?'可蚁点司机版':scope.type==2?'可蚁点跑腿版':scope.type==4?'可蚁点商家版':'其它版'}}
								</template> -->
							</el-table-column>
							<el-table-column prop="name" label="商家名称" show-overflow-tooltip width="180" />
							<el-table-column prop="car_number" label="车牌号" show-overflow-tooltip width="120" />
							<el-table-column prop="phone" label="手机号" show-overflow-tooltip width="140" />
							<el-table-column prop="address" label="地区" show-overflow-tooltip min-width="120" />
							<el-table-column label="最近学习时间" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">{{ row.active_time*1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column prop="study_today" label="今日学习时长(分钟)" show-overflow-tooltip width='160px' />
							<el-table-column prop="study_all" label="累计学习时长(分钟)" show-overflow-tooltip width='160px' />
							<el-table-column label="操作" min-width="180px" align="center" :resizable="false"
								fixed="right">
								<template slot-scope="{row}">
									<el-link @click="handleDriverDetail(row)" icon="el-icon-view" type="primary"
										:underline="false" v-if="permission.includes('sys:course:edit')">查看</el-link>
									<el-link @click="statistical(row)" icon="el-icon-finished" type="primary"
										:underline="false" v-if="permission.includes('sys:course:edit')">统计</el-link>

								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-tab-pane>
				<!--轮播图-->
				<el-tab-pane label="轮播图" name="course_banner">
					<el-form :model="table.where" class="ele-form-search d-flexspabet  mt-20 mb-20"
						@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
						<div>
							<el-button @click="dialogTableVisiblebanner=true" class="ele-btn-icon addbtn" size="small"
								v-if="permission.includes('sys:banner:add')">添加轮播图
							</el-button>
						</div>
					</el-form>
					<ele-data-table ref="table2" :config="table2" :choose.sync="choose" height="calc(100vh - 315px)"
						highlight-current-row :stripe=true>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip />
							<el-table-column prop="img" label="轮播图" show-overflow-tooltip>
								<template slot-scope="{row}">
									<!-- <el-avatar shape="square" :size="20" :src="row.img" style="width:80px;height:30px" /> -->
									<el-image style="width:80px;height:30px;" :src="row.img" fit="cover" :preview-src-list="[row.img]"></el-image>
								</template>
							</el-table-column>
							<el-table-column prop="side_type" label="类型" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">
									{{row.side_type==2 ? '可蚁点司机版':(row.side_type==3 ? '可蚁点跑腿版':
									row.side_type==4 ? '可蚁点商家版':'类型未定义')}}
								</template>
							</el-table-column>
							<el-table-column label="更新时间" show-overflow-tooltip min-width='150px'>
						<template slot-scope="{row}">{{ row.update_time*1000 | toDateString }}</template>
					</el-table-column>
							<el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link @click="handleBannerEdit(row)" icon="el-icon-edit" type="primary"
										:underline="false" v-if="permission.includes('sys:banner:edit')">编辑</el-link>
									<el-link @click=" banner_remove(row)" slot="reference" icon="el-icon-delete"
										type="danger" :underline="false"
										v-if="permission.includes('sys:banner:delete')">删除</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-tab-pane>
			</el-tabs>
		</el-card>

		<!-- 添加/编辑课程学习弹窗 -->
		<el-dialog v-dialogDrag :title="editForm.id?'编辑课程':'添加课程'" :visible.sync="showEdit" @closed="editForm={}"
			:destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">
					<el-form-item label="课程标题：" prop="name">
						<el-input v-model="editForm.name" placeholder="" clearable />
					</el-form-item>
					<el-form-item label="类型：" prop="type">
						<el-select v-model="editForm.type" placeholder="请选择" clearable class="ele-fluid">
							<!-- <el-option label="咱县打车司机版" :value="2" />
							<el-option label="咱县打车跑腿版" :value="3" />
							<el-option label="咱县打车商家版" :value="4" /> -->
							<el-option label="可蚁点司机版" :value="2" />
							<el-option label="可蚁点跑腿版" :value="3" />
							<el-option label="可蚁点商家版" :value="4" />
						</el-select>
					</el-form-item>
					<el-form-item label="封面图：" prop="image">
						<uploadImage :limit="1" v-model="editForm.image"></uploadImage>
					</el-form-item>
					<el-form-item label="图文内容：">
						<tinymce-editor v-model="editForm.content" :init="editContent" />
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="showEdit=false">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>
		<!--查看课程详情 -->
		<el-dialog v-dialogDrag title="查看课程详情" :visible.sync="showEditWatch" @closed="watchForm={}" :destroy-on-close="true"
			custom-class="ele-dialog-form" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="watchForm" ref="watchForm" label-width="100px">
					<el-form-item label="课程标题：" prop="title">
						{{watchForm.name}}
					</el-form-item>
					<el-form-item label="类型：">
						{{watchForm.type_name}}
					</el-form-item>
					<el-form-item label="浏览次数(次)：" prop="title">
						{{watchForm.num}}
					</el-form-item>
					<el-form-item label="创建时间：" prop="title">
						{{ watchForm.create_time*1000 | toDateString }}
					</el-form-item>
					<el-form-item label="课程图片：">
						<el-image style="width: 100px; height: 100px" :src="watchForm.image" fit="cover" :preview-src-list="[watchForm.image]"></el-image>
					</el-form-item>
					<el-form-item label="图文内容：" prop="title">
						<tinymce-editor v-model="watchForm.content" :init="editContent" />
					</el-form-item>
				</el-form>
			</el-card>
		</el-dialog>
		<!-- 查看商家详情 -->
		<el-dialog v-dialogDrag title="查看商家详情" :visible.sync="dialogTableVisibleDriverDetail" class="driverDetail" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<div style="margin-left:28px">
					<div class="mb-15"><span class="foWei6">司机姓名：</span>{{courseDateil.true_name}} </div>
					<div class="mb-15"><span class="foWei6">类型：</span>
						{{courseDateil.type}}
					</div>
					<div class="mb-15"><span class="foWei6">手机号：</span>{{courseDateil.phone}} </div>
					<div class="mb-15"><span class="foWei6">地区：</span>{{courseDateil.area}} </div>
				</div>
				<div class="greenLine"></div>
				<div class="block" style="margin-left: 30px;">
					<el-timeline :reverse="reverse">
						<el-timeline-item v-for="(activity, index) in activities" :key="index"
							:timestamp="activity.name">
							{{activity.end_time}}
						</el-timeline-item>
					</el-timeline>
				</div>
			</el-card>
		</el-dialog>
		<!-- 查看课程详情 -->
		<el-dialog v-dialogDrag title="查看课程详情" :visible.sync="dialogTableVisibleCourse" width="850px" class="course">
			<el-card shadow="never">
				<div style="margin-left:28px">
					<div class="mb-15"><span class="foWei6">司机姓名：</span>{{driverData.true_name}} </div>
					<div class="mb-15"><span class="foWei6">类型：</span>{{driverData.type}} </div>
					<div class="mb-15"><span class="foWei6">手机号：</span>{{driverData.phone}} </div>
					<div class="mb-15"><span class="foWei6">地区：</span>{{driverData.area}} </div>
				</div>
				<div class="greenLine"></div>
				<div class="statist" style="margin-left: 30px;">学习记录统计：</div>
				<div id="main" style="width:100%;height:300px;">
				</div>
			</el-card>
		</el-dialog>
		<!--添加/编辑轮播图 -->
		<el-dialog v-dialogDrag :title="bannerIMG.id?'编辑课程轮播图':'添加课程轮播图'" :visible.sync="dialogTableVisiblebanner"
			@closed="bannerIMG={}" :destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="bannerIMG" ref="bannerIMG" :rules="editRules1" label-width="120px">
					<el-row :gutter="15">
						<el-col :sm="12">
							<el-form-item label="显示版：" prop="side_type">
								<el-select v-model="bannerIMG.side_type" placeholder="请选择" clearable class="ele-fluid"
									:disabled="bannerIMG.id?true:false">
									<el-option v-for="option in typeArr " :value="option.type" :key="option.type"
										:label="option.name"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item label="课程轮播图：" prop="img">
						<uploadImage :limit="1" v-model="bannerIMG.img"></uploadImage>
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="dialogTableVisiblebanner=false">取消</el-button>
				<el-button type="primary" @click="bannerSave">保存</el-button>
			</div>
		</el-dialog>

	</div>

</template>

<script>
	import TinymceEditor from '@/components/TinymceEditor'
	import {
		mapGetters
	} from "vuex";
	import uploadImage from '@/components/uploadImage';
	export default {
		name: "SysNotice",
		components: {
			TinymceEditor,
			uploadImage
		},
		data() {
			return {
				reverse: true,
				activities: [],
				active: 'course_data',
				table: {
					url: '/course/index',
					where: {}
				}, // 表格配置
				table1: {
					url: '/course/get_study_list',
					where: {}
				}, // 表格配置
				table2: {
					url: '/course/banner_index',
					where: {}
				}, // 表格配置
				driverData: {},
				courseDateil: {},
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				bannerEdit: false,
				dialogTableVisibleCourse: false,
				dialogTableVisibleDriverDetail: false,
				dialogTableVisiblebanner: false,
				showEditWatch: false,
				watchForm: [],
				editForm: {}, // 表单数据
				bannerIMG: {},
				provArr: [],
				cityArr: [],
				districtArr: [],
				typeArr:[
					// {
					// 	type:'2',
					// 	name:'咱县打车司机版'
					// },{
					// 	type:'3',
					// 	name:'咱县打车跑腿版'
					// },{
					// 	type:'4',
					// 	name:'咱县打车商家版'
					// },
					{
						type:'2',
						name:'可蚁点司机版'
					},{
						type:'3',
						name:'可蚁点跑腿版'
					},{
						type:'4',
						name:'可蚁点商家版'
					}
				],
				editRules: { // 表单验证规则
					name: [{
						required: true,
						message: '请输入课程标题',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择显示版',
						trigger: 'change'
					}],
					image: [{
						required: true,
						message: '请选择封面图',
						trigger: 'blur'
					}],
				},
				editRules1: { // 表单验证规则
					side_type: [{
						required: true,
						message: '请选择显示版',
						trigger: 'change'
					}],
					img: [{
						required: true,
						message: '请选择图片',
						trigger: 'change'
					}],
				},
				bannerRules: {
					image: [{
						required: true,
						message: '请上传课程封面图',
						trigger: 'change'
					}],
					bannerForm: [],
					type: ''
				},
				// 自定义文件上传(这里使用把选择的文件转成blob演示)
				file_picker_callback: (callback, value, meta) => {
					let input = document.createElement('input');
					input.setAttribute('type', 'file');
					// 设定文件可选类型
					if (meta.filetype === 'image') {
						input.setAttribute('accept', 'image/*');
					} else if (meta.filetype === 'media') {
						input.setAttribute('accept', 'video/*');
					}
					input.onchange = () => {
						let file = input.files[0];
						let reader = new FileReader();
						reader.onload = (e) => {
							let blob = new Blob([e.target.result], {
								type: file.type
							});
							callback(URL.createObjectURL(blob));
						};
						reader.readAsArrayBuffer(file);
					}
					input.click();
				}
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				var data = JSON.parse(res.data)
				this.provArr = data
			})
		},
		computed: {
			...mapGetters(["permission"]),
			editContent() {
				return {
					menubar: false,
					// file_picker_callback: this.file_picker_callback,
					skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' :
						'/tinymce/skins/ui/oxide',
					content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' :
						'/tinymce/skins/content/default/content.css'
				};
			}
		},
		mounted() {},
		methods: {
			/**选择省 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				var pid = ''
				this.provArr.forEach(function(item) {
					if (item.name == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**选择市**/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				var cid = ''
				this.cityArr.forEach(function(item) {
					if (item.name == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},

			/* 查看课程列表 */
			editWatch(row) {
				this.showEditWatch = true;
				this.watchForm = row;
			},
			/* 编辑课程列表 */
			edit(row) {
				this.showEdit = true;
				this.editForm = row;
			},
			/* 添加课程列表 */
			save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/course/edit', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			/* 删除课程列表 */
			remove(row) {
				if (!row) { // 批量删除
					if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
					let ids = this.choose.map(d => d.id);
					this.$confirm('确定要删除选中的课程吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/course/delete', {
							id: ids
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}).catch(() => 0);
				} else { // 单个删除
					this.$confirm('确定要删除选中的课程吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/course/delete', {
							id: row.id
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			},

			/* 学习情况-统计 */
			statistical(row) {
				this.dialogTableVisibleCourse = true
				const loading = this.$loading({
					lock: true,
					target: document.querySelector(".course")
				});
				this.$http.post('/course/get_study_log', {
					id: row.id
				}).then(res => {
					loading.close()
					var data = res.data
					this.driverData = data.driver_data
					var stringXResult = data.x_datas
					var stringYResult = data.y_datas
					var xData = stringXResult.split(',')
					var yData = stringYResult.split(',')
					this.$nextTick(() => {
						let dom = document.getElementById('main')
						var myChart = this.$echarts.init(dom);
						var option;
						option = {
							toolbox: {
								show: true,
								feature: {
									saveAsImage: { //保存图片
										show: true
									},
								}
							},
							xAxis: {
								name:'日期',
								type: 'category',
								data: xData
							},
							yAxis: {
								type: 'value',
								name:'小时（h）'
							},
							series: [{
								data: yData,
								type: 'line',
								itemStyle: {
									normal: {
										color: '#009F95',
										lineStyle: {
											color: '#009F95'
										}
									}
								},
							}],

						};
						option && myChart.setOption(option);
					})

				})
			},
			/* 学习情况-查看 */
			handleDriverDetail(row) {
				this.dialogTableVisibleDriverDetail = true
				const loading = this.$loading({
					lock: true,
					target: document.querySelector(".driverDetail")
				});
				this.$http.post('/course/get_show_study', {
					id: row.id
				}).then(res => {
					loading.close()
					this.courseDateil = res.data.driver
					this.activities = res.data.coursestudy
				})
			},

			/* 编辑轮播图 */
			handleBannerEdit(row) {
				this.dialogTableVisiblebanner = true
				this.bannerIMG = row
			},
			/* 添加轮播图 */
			bannerSave() {
				this.$refs['bannerIMG'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/course/banner_add', this.bannerIMG).then(res => {
							this.dialogTableVisiblebanner = false;
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table2.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			/* 删除轮播图 */
			banner_remove(row) {
				if (!row) { // 批量删除
					if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
					let ids = this.choose.map(d => d.id);
					this.$confirm('确定删除此轮播图吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/course/banner_delete', {
							id: ids
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table2.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}).catch(() => 0);
				} else { // 单个删除
					this.$confirm('确定删除此轮播图吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/course/banner_delete', {
							id: row.id
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table2.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			},
		}
	}
</script>

<style scoped>
	.ele-block .el-upload .el-upload-dragger {
		width: 100%;
	}

	/deep/.el-dialog {
		margin-top: 60px !important;
	}
</style>
